@font-face {
	font-family: 'Open Sans';
	src: url('https://fonts.atupri.ch/v2/OpenSans-Regular.eot');
	src: url('https://fonts.atupri.ch/v2/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
		url('https://fonts.atupri.ch/v2/OpenSans-Regular.woff') format('woff'),
		url('https://fonts.atupri.ch/v2/OpenSans-Regular.ttf') format('truetype'),
		url('https://fonts.atupri.ch/v2/OpenSans-Regular.svg#open_sansregular') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('https://fonts.atupri.ch/v2/OpenSans-Semibold.eot');
	src: url('https://fonts.atupri.ch/v2/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
		url('https://fonts.atupri.ch/v2/OpenSans-Semibold.woff') format('woff'),
		url('https://fonts.atupri.ch/v2/OpenSans-Semibold.ttf') format('truetype'),
		url('https://fonts.atupri.ch/v2/OpenSans-Semibold.svg#open_sanssemibold') format('svg');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('https://fonts.atupri.ch/v2/OpenSans-Bold.eot');
	src: url('https://fonts.atupri.ch/v2/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('https://fonts.atupri.ch/v2/OpenSans-Bold.woff') format('woff'),
		url('https://fonts.atupri.ch/v2/OpenSans-Bold.ttf') format('truetype'),
		url('https://fonts.atupri.ch/v2/OpenSans-Bold.svg#open_sansbold') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Neutraface 2 Text';
	src: url('https://fonts.atupri.ch/v2/neutraface2text-bold.eot');
	src: url('https://fonts.atupri.ch/v2/neutraface2text-bold.eot?#iefix') format('embedded-opentype'),
		url('https://fonts.atupri.ch/v2/neutraface2text-bold.woff') format('woff'),
		url('https://fonts.atupri.ch/v2/neutraface2text-bold.ttf') format('truetype'),
		url('https://fonts.atupri.ch/v2/neutraface2text-bold.svg#neutraface2text-bold') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Atupri Icons';
	src: url('https://fonts.atupri.ch/fonts/atupri_icons/atupri_icons.eot');
	src: url('https://fonts.atupri.ch/fonts/atupri_icons/atupri_icons.eot?#iefix') format('embedded-opentype'),
		url('https://fonts.atupri.ch/fonts/atupri_icons/atupri_icons.woff') format('woff'),
		url('https://fonts.atupri.ch/fonts/atupri_icons/atupri_icons.ttf') format('truetype'),
		url('https://fonts.atupri.ch/fonts/atupri_icons/atupri_icons.svg') format('svg'),
		url('https://fonts.atupri.ch/fonts/atupri_icons/atupri_icons.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
